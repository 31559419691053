// reset password
.reset-password {
	padding: 39px 0 44px;
	@include screen(custom, max, $max-sm) {
		padding: 21px 0 29px;
	}
	.reset-password-title {
		font-size:32px;
		@include font-family($font-semibold);
		text-align:center;
		line-height:40px;
		margin-bottom: 25px;
		@include screen(custom, max, $max-sm) {
			font-size:24px;
			line-height:32px;
			margin-bottom:0;
		}
	}
	.info-text{
		font-size:14px;
		line-height:20px;
		color:$color-dimgray;
		margin:4px 0 0 10px;	
		padding-bottom:5px;
	}
	dl {
		@include screen(custom, max, $max-sm) {
			margin-top:15px;
		}
	}
	// dl{
	// 	margin:21px 0 0 0;
	// 	@include screen(custom, max, $max-sm) {
	// 		margin-top:10px;
	// 	}
	// 	dt{
	// 		margin:0;
	// 		font-size:16px;
	// 		line-height:24px;
	// 		color:$color-nightrider;
	// 		@include font-family($font-semibold);
	// 		@include screen(custom, max, $max-sm) {
	// 			font-size:14px;
	// 			line-height:20px;
	// 		}
	// 		label{
	// 			margin:0;
	// 		}
	// 	}
	// 	dd{
	// 		margin:0;
	// 		margin-top:7px;
	// 		input{
	// 			width:100%;
	// 		}
			
	// 		@include screen(custom, max, $max-sm) {
	// 			margin-top:5px;
	// 		}
	// 	}
	// }
	// .btn-area{
	// 	text-align:center;
	// 	margin-top:21px;
	// 	button{width:244px;}
	// 	@include screen(custom, max, $max-sm) {
	// 		margin-top:24px;
	// 		button{width:100%;}
	// 	}
	// }
	
	.field-container{
		.error-msg{
			// margin-top:6px;
			span {display:inline-block;}
			.notice:before{background:url('/lg5-common/images/common/icons/notice-carmine.svg') no-repeat 0 0;}
		}
		.btn-area + .error-msg {
			margin: 10px 0 0;
			text-align: center;
		}
	}
}
// reset password result
.reset-password-result{
	text-align:center;
	padding-top:111px;
	padding-bottom:125px;
	@include screen(custom, max, $max-sm) {
		padding-top:91px;
		padding-bottom:109px;
	}
	.title {
		font-size:32px;
		@include font-family($font-semibold);
		color:$color-black;
		@include screen(custom, max, $max-sm) {
			font-size:24px;
			line-height:32px;
			margin-bottom:6px;
		}
	}
	.text {
		margin:1px 0 0;
		font-size:16px;
		line-height:24px;
		color:$color-nightrider;
		@include font-family($font-regular);
		text-align:center;
		@include screen(custom, max, $max-sm) {
			font-size:14px;
			line-height:20px;
		}
	}
	.btn-area{
		margin-top:43px;
		@include screen(custom, max, $max-sm) {
			margin-top:35px;
		}
		.btn {width: map-get($signin, btn-fixed-width);}
	}
}