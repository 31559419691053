// common variable
$signin : (
	frame-margin : 30px,
	frame-width: 1284px,
	frame-column-width: 412px,
	btn-full-width : 100%,
	btn-fixed-width : 248px
);

// fit margin in signin pages
.customer-title .txt-area .txt-wrap .desc:empty {
	margin-bottom:-3px;
}

.signin-wrap {
	max-width:map-get($signin, frame-width)+ 30px;
	margin:0 auto;
	padding:0 15px 70px 15px;
	// 1199px ~
	@include screen(custom, max, $max-lg){
		padding:0;
	}
	// 767px ~
	@include screen(custom, max, $max-sm){
		margin:0 9px;
	}
	// 1199px ~ 768px
	@include screen(custom, min, $min-md){
		@include screen(custom, max, $max-lg){
			padding:0 15px 50px;
		}
	}
	/* PJTEMP-1 20200603 add */
	.sign-desc-wrap {
		@extend %clearfix;
		padding: 20px 0 20px;
		.signin-desc {
			float: left;
			width: calc(100% - 140px);
		}
		.require-area {
			float: right;
			width: 140px;
			white-space: nowrap;
			.require {
				margin:0 4px;
			}
		}
		.signin-desc,
		.require-area {
			margin: 0;
		}
		@include screen(custom, max, $max-sm) {
			padding: 20px 0 12px;
			.signin-desc,
			.require-area {
				width: 100%;
			}
			.signin-desc {
				margin-bottom: 5px;
			}
			.require-area {
				font-size:14px;
			}
		}
	}
	/* //PJTEMP-1 20200603 add */
	.inner {
		background-color:$bg-whitesmoke;
		border:1px solid $line-gray;
		padding:0 map-get($signin, frame-margin);
		@include screen(custom, max, $max-sm) {
			padding:0 20px;
		}
		/* PJTEMP-1 20200603 add */
		&.account-integration {
			$paddingLeft : 0;
			$marginLeft: auto;
			padding: 0;
			border: none;
			background-color: transparent;
			// .integration-wrap {
			// 	border-top: 1px solid $line-gray;
			// }
			.verification-failed {
				.failed-text {
					padding: 30px 0;
					font-size: 28px;
    				line-height: 36px;
				}
				.btn-area {
					border: none;
				}
			}
			/* PJTEMP-1 202000708 add */
			.signup-completed {
				.top {
					margin-top: -10px;
					margin-bottom: 27px;
					padding-bottom: 34px;
					border-bottom: 1px solid $line-gray;
				}
				.text {
					font-size: 16px;
					line-height: 24px;
					color: $color-dimgray;
					text-align: center;
					& + .text {
						margin-top: 22px;
					}
					&.em {
						@include font-family($font-semibold);
						font-size: 20px;
						line-height: 24px;
						color: $color-black;
						strong {
							color: $color-carmine;
						}
						& + .text {
							margin-top: 5px;
						}
					}
				}
				.btn-area {
					padding-top: 37px;
					border: none;
				}
			}
			/* // PJTEMP-1 202000708 add */
			.field-container {	// used only no sign-in pages.
				max-width:none;
				padding: 0;
				@include screen(custom, max, $max-lg){
					max-width:none;
				}
			}
			.box {
				padding: 34px $paddingLeft 49px;
				margin: 0 $marginLeft;
				max-width: 850px;
				//background-color:$bg-whitesmoke;
				//border:1px solid $line-gray;
				@include screen(custom, max, $max-sm) {
					margin: 0;
					padding: 23px 0 25px !important;
				}
				/* PJTEMP-1 20200612 add */
				
				.search-input, input, select, .chosen-single {
					background-color: #fdfdfd;
					color: $color-dimgray;
					&:focus {
						background-color: #f8fbff;
						& + .calendar-view.datepicker-trigger {
							background-color: #f8fbff;
						}
					}
				}
				.chosen-container-active .chosen-single {
					background-color: #f8fbff;
				}
				.calendar-view.datepicker-trigger {
					width: 40px;
					height: 40px;
					background-color: #fdfdfd;
					background-position-x: -2px;
					color: $color-black;
				}
				/* //PJTEMP-1 20200612 add */
				.head-title{
					@include font-family($font-semibold);
					text-align: left;
					font-size: 24px;
					line-height: 40px;
					color: $color-black;
				}
				.guide-title, 
				.title {
					display: block;
					margin-bottom: 1px;
					font-size: 32px;
					line-height: 1.5;
					color: $color-black;
					@include font-family($font-semibold);
					@include screen(custom, max, $max-sm) {
						margin-bottom: 8px;
						font-size: 24px;
						line-height: 1.2;
					}
					& + .sub-text {
						margin-bottom: 22px;
						@include screen(custom, max, $max-sm) {
							margin-bottom: 0;
						}
					}
				}
				.guide-subtext,
				.sub-text {
					display: block;
					margin-bottom: 41px;
					font-size: 16px;
					color: $color-dimgray;
					@include screen(custom, max, $max-sm) {font-size: 14px; line-height: 20px; margin:  0 auto 19px auto;} 
				}
				&:first-child {
					& + .box {
						position: relative;
						padding-top: 28px;
						border-top: none;
						&:before {
							content: '';
							position: absolute;
							left: 0;
							right: 0;
							top: 0;
							border-top: 1px solid $line-gray;
							@include screen(custom, max, $max-sm) {
								left: 19px;
								right: 19px;
							}
						}
					}
				}
				&.policy {
					position: relative;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						border-top: 1px solid $line-gray;
						@include screen(custom, max, $max-sm) {
							left: 19px;
							right: 19px;
						}
					}
					.sign-up-chk {
						padding-top: 0;
						margin-top: 0;
						border-top: none;
						.require {
							margin: 0 5px 0 0;
						}
					}
				}
				&.account-result {
					padding: 63px $paddingLeft 20px;
					margin: 0 $marginLeft;
					background-color: transparent;
					border: none;
					.no-content {
						text-align: center;
						.icon {
							display: block;
							padding-bottom: 15px;
						}
						svg {
							fill: $line-gray;
						}
					}
					.sub-text {
						margin-top: 11px;
					}
					dl.list {
						margin-top: 36px;
						padding: 27px 0 28px;
						border: solid $line-gray;
						border-width: 1px 0;
						@include screen(custom, max, $max-sm) {
							padding: 17px 0 18px;
						}
						dt {
							display:inline-block;
							width:136px;
							color:$color-nightrider;
							@include font-family($font-regular);
							@include list-bullet($position:10px, $size:3px);
							@include screen(custom, max, $max-sm) {
								font-size:14px;
								width:50%;
							}
						}
						dd {
							display:inline-block;
							color:$color-nightrider;
							font-weight:400;
							margin-bottom:0;
							@include screen(custom, max, $max-sm) {
								font-size:14px;
							}
						}
					}
					.bottom-info {
						padding: 18px 0;
						p {
							color:$color-dimgray;
							@include font-family($font-regular);
							font-size: 16px;
							line-height: 24px;
							& + p {
								margin-top: 19px;
							}
						}
						.link-text {
							text-decoration: underline;
						}
					}
				}
			}
			.btn-area {
				padding-top: 50px;
				border-top: 1px solid $line-gray;
				text-align: center;
				.btn {
					min-width: 248px;
					& + .btn {
						margin-left: 7px;
					}
				}
				@include screen(custom, max, $max-sm) {
					padding-top: 29px;
					.btn {
						display: block;
						min-width: 100%;
						& + .btn {
							margin-top: 10px;
							margin-left: 0;
						}
					}
				}
			}
		}
		/* // PJTEMP-1 20200603 add */
	}

	/* PJTEMP-1 20200722 add */
	&.emp-background {
		.inner {
			background-color: transparent;
			border: none;
		}
	}
	/* //PJTEMP-1 20200722 add */

	// signin common elements
	.require-area {
		margin:-6px 0 13px;
		@include screen(custom, max, $max-sm) {
			margin-bottom:7px;
			font-size:14px;
			line-height:20px;
		}
	}
	.signin-desc {
		float:left;
	}
	.field-wrap {
		position:relative;
		max-width:map-get($signin, frame-width)- (150px*2);
		margin:auto;
	}
	.field-container {	// used only no sign-in pages.
		max-width:848px;
		margin:auto;
		// 1199px ~ 768px
		@include screen(custom, min, $min-md){
			@include screen(custom, max, $max-lg){
				max-width:848px;
			}
		}
		// 320px ~ 767px
		@include screen(custom, max, $max-sm){
			padding:0;
			> [class*="col"] {padding:0}
		}
	}
	.field-block input {width:100%;}
	.column-box{
		@extend %clearfix;
		@include screen(custom, min, $min-md) {
			/* PJTEMP-1 20200603 add */
			.left-column {
				& + .left-column {
					clear: left;
				}
			}
			/* //PJTEMP-1 20200603 add */
			.left-column,
			.right-column {
					float:left;
					width:50%;
			}
			.left-column {padding-right:12px;}
			.right-column {padding-left:12px;}
		}
	}
	.area-boxing {
		width:100%;
		@include screen(custom, min, $min-md) {
			max-width:map-get($signin, frame-column-width);
		}
	}
	
	// all pages common
	.common-sign-in-area,
	.extra-sign-in-area,
	.order-area {
		// reset
		dl, dd, label,.dl_box,.dt_box,.dd_box {
			margin:0;
		}
		dl{
			&.password-block,
			&.email-phone-block {
				margin-top: 24px;
			}
			&.password-block {
				@include screen(custom, max, $max-sm) {
					margin-top:25px;
				}
			}
			&.email-phone-block {
				@include screen(custom, max, $max-sm) {
					margin-top:14px;
				}
			}
			dt{
				font-size:16px;
				line-height:24px;
				color:$color-nightrider;
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size:14px;
					line-height:20px;
				}
			}
			dd{
				margin-top:7px;
				input{
					width:100%;
				}
				&.check-box{
					margin-top:9px;
				}
				@include screen(custom, max, $max-sm) {
					margin-top:5px;
					&.check-box{
						margin:9px 0;
					}
				}
			}
		}
		.dl_box{
			&.password-block,
			&.email-phone-block {
				margin-top: 24px;
			}
			&.password-block {
				@include screen(custom, max, $max-sm) {
					margin-top:25px;
				}
			}
			&.email-phone-block {
				@include screen(custom, max, $max-sm) {
					margin-top:14px;
				}
			}
			.dt_box{
				font-size:16px;
				line-height:24px;
				color:$color-nightrider;
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size:14px;
					line-height:20px;
				}
			}
			.dd_box{
				margin-top:7px;
				input{
					width:100%;
				}
				&.check-box{
					margin-top:9px;
				}
				@include screen(custom, max, $max-sm) {
					margin-top:5px;
					&.check-box{
						margin:9px 0;
					}
				}
			}
		}
		.btn-area{
			text-align:center;
			margin-top:30px;
			@include screen(custom, max, $max-sm) {
				margin-top:24px;
			}
		}
	}
	
	// signin 
	// left area
	.common-sign-in-area {
		@include screen(custom, min, $min-md) {
			width:50%;
			padding-right:map-get($signin, frame-margin);
			margin:43px 50% 43px 0;
		}
		@include screen(custom, max, $max-sm) {
			padding-top:23px;
		}
		.btn-area .btn {
			width:map-get($signin, btn-full-width);
		}
		.btn-forgot{
			margin:15px 0 0;
			text-align:center;
			.link-text {
				color:$color-nightrider;
				&.collapsed{color:$color-nightrider;}
				& + .link-text:before {
					content: "";
					display: inline-block;
					width: 1px;
					height: 11px;
					background: #999;
					vertical-align: middle;
					margin: -4px 10px 0;
				}
			}
			@include screen(custom, max, $max-sm) {
				margin-top:23px;
				.link-text {
					font-size:14px;
					line-height:1.5;
					color:$color-nightrider;
				}
			}
		}
		.find-password-area {
			padding-top:25px;
			@include screen(custom, max, $max-sm) {
				padding-top:5px;
			}
		}
		.forgot-area{
			text-align:center;
			&:before {
				content:"";
				display:block;
				max-width:848px;
				height:1px;
				background-color:$line-gray;
				margin:0 auto;
				@include screen(custom, max, 948 ){
					margin:0 20px;
				}
				@include screen(custom, max, $max-sm){
					margin:10px 0px 0px;
				}
			}
			&:after {
				content:"";
				display:block;
				width:100%;
				padding-top:9px;
			}
			.forgot-area-con{
				padding-top: 30px;
				.field-block{
					width:calc(100% - 109px); // You will must be edit to fit button size. default is "SUBMIT"
					display:inline-block;
					vertical-align: top;
					text-align: left;
					strong{font-weight:normal;}
					/* PJTWAUS-1 20200325 add : element 줄바꿈 떨어짐 방지 */ 
					@include screen(custom, min, $min-md) {
						white-space: nowrap;
						font-size: 0;
						.error-msg {
							white-space: normal;
							font-size: inherit;
						}
					}
					/* // PJTWAUS-1 20200325 add : element 줄바꿈 떨어짐 방지 */ 

					/* PJTWAUS-1 20200212 modify */ 
					@include screen(custom, max, $max-sm) {
						float:none;
						width:100%;
						display:block;
					}
					dl{
						dt.sr-only{
							+ dd{margin:0;}
						}
					} /* //PJTWAUS-1 20200212 modify */
				}
				button{
					vertical-align: top;
					margin-left:10px;
					margin-top: 2px;
					@include screen(custom, max, $max-sm) {
						width:100%;
						margin:10px 0 0;
					}
				}
				@include screen(custom, max, $max-sm) {
					display:block;
					padding-top: 20px;
				}
			}
		}
		.notice-area{
			max-width:630px;
			margin:0 auto;
			padding-top:10px;
			.notice{
				display:inline-block;
				text-align:left;
				h2{
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
		// notice-area variation
		.incorrect-account {
			padding-bottom:6px;
			text-align:center;
			.notice {
				color:$color-carmine;
				&:before {
					background-image:url(/lg5-common/images/common/icons/notice-carmine.svg);
				}
			}
			@include screen(custom, max, $max-sm) {
				margin-bottom: -11px;
			}
		}
	}
	// right area
	.reference-sign-in-area {
		@include screen(custom, min, $min-md) {
			position:absolute;
			top:0; right:0;
			width:50%;
			height:100%;
			padding-left:map-get($signin, frame-margin);
		}
		@include screen(custom, max, $max-sm) {
			padding-bottom: 23px;
		}
		&:before {
			content:"";
			background-color:$line-gray;
			@include screen(custom, min, $min-md) {
				position:absolute;
				left:0; top:0;
				width:1px;
				height:100%;
			}
			@include screen(custom, max, $max-sm) {
				display:block;
				width:100%;
				height:1px;
				margin:23px 0 19px;
			}
		}
		.area-boxing {
			@include screen(custom, min, $min-md) {
				margin:-6px 0 0 auto;
			}
		}
		/* LGEGMC-567 20200910 add */
		.box-content.btn-type{
			width:100%;
			.btn-area .btn {
				width:map-get($signin, btn-full-width);
			}
		}
		/* //LGEGMC-567 20200910 add */
		// checkout
		.checkout-area {
			@include screen(custom, max, $max-sm) {
				text-align: center;
			}
			.area-head {
				@include font-family($font-semibold);
				font-size:24px;
				line-height: 40px;
				color:$color-black;
				margin-bottom: 2px;
				@include screen(custom, max, $max-sm) {
					font-size:20px;
					line-height:28px;
					margin-bottom: 10px;
				}
			}
			.desc {
				font-size:16px;
				line-height:24px;
				color:$color-dimgray;
				margin-bottom: 23px;
				margin-right: 5px;
				@include screen(custom, max, $max-sm) {
					font-size:14px;
					line-height:20px;
					margin-bottom: 19px;
				}
			}
			.btn {
				width:map-get($signin, btn-full-width);
			}
			.sub-desc {
				font-size:16px;
				line-height:24px;
				color:$color-nightrider;
				margin-top: 24px;
				span {margin-right: 10px;}
				@include screen(custom, max, $max-sm) {
					font-size:14px;
					line-height:20px;
					margin-top: 14px;
				}
			}
		}
		// order
		.order-area {
			@include screen(custom, min, $min-md) {
				margin-top:0;
			}
			@include screen(custom, max, $max-sm) {
				padding-top: 5px;
			}
			.btn-area .btn {
				width:map-get($signin, btn-full-width);
			}
			.sub-desc {
				font-size:16px;
				line-height:24px;
				color:$color-nightrider;
				margin-top: 24px;
				span {margin-right: 10px;}
				@include screen(custom, max, $max-sm) {
					text-align: center;
					font-size:14px;
					line-height:20px;
					margin-top: 14px;
				}
			}
		}
	}
	
	// no signin.
	.extra-sign-in-area {
		.sign-in-text{
			padding:4px 0 3px;
			font-size:16px;
			line-height:24px;
			color:$color-dimgray;
			@include font-family($font-regular);
		}
		.sign-in-title{
			text-align:center;
			font-size:32px;
			line-height:40px;
			color:$color-black;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-sm) {
				font-size:24px;
				line-height:32px;
			}
		}
		.sign-in-desc {
			max-width:80%;
			margin:5px auto 0;
			text-align:center;
			font-size:16px;
			line-height: 24px;
			color:$color-dimgray;
			@include screen(custom, max, $max-sm) {
				max-width:none;
				font-size:14px;
				line-height:20px;
				margin:5px 0;
				br {display:none;}
			}
		}
		.btn-area .btn {width:map-get($signin, btn-fixed-width);}
	}
	legend{
		width:1px;
		height:1px;
		display:block;
		overflow:hidden;
		text-indent:-9999px;
		margin:0;
	}

	.sub-text.otpField {
		display: flex;
		flex-direction: column;
		margin-bottom: 0px; 
	}
	
	.sub-text.otpField .otp-label {
		font-weight: bold;
		margin-bottom: 5px;
	}

	.sub-text.otpField .otp-input-wrapper {
		display: flex;
		align-items: center;
	}
	
	.sub-text.otpField input#otpCode {
		margin-right: 10px;
    	width: auto;
	}
	
	.sub-text.otpField .error-msg {
		display: none;
		margin-left: 10px; 
		margin-bottom: 10px;
	}
	
	.sub-text.otpField .required {
		display: none; 
	}
	
	.sub-text.otpField b {
		font-weight: bold;
	}
}
 

