.modal {
    .find-my-password{
        text-align:center;
        .msg-box {
            .email {
                display:block;
                margin-top:3px;
                @include font-family($font-semibold);
                font-size:24px;
                color:$color-carmine;
            }
            padding:28px 0 31px 0;
            text-align:center;
            color:$color-nightrider;
            font-size:16px;
        }

        .notice {  
            display:inline-block;
            text-align:left;
         }

        .contact-info-box {
            .text {
                display:block; 
                // @include font-family($font-semibold);
                font-size:20px;
                color:$color-nightrider;
            }
            .contact-link {
                display:inline-block;
                font-size:16px;
                color:$color-dimgray;
                margin-top:7px;
            }
            margin-top:14px;
            padding:21px 0 26px 0;
            text-align:center;
            background:$bg-whitesmoke;
        }
        @include screen(custom, max, 560){
            .msg-box {
                .email {
                    margin-top:6px;
                    font-size:20px;
                    line-height: 28px;
                }
                padding:18px 0 22px 0;
            }
            .notice {  
                font-size:14px;
             }
             .contact-info-box {
                .text {
                  font-size:16px;
                }
                .contact-link {
                   font-size:14px;
                   line-height: 20px;
                }
                margin-top:13px;
                padding:25px 20px 22px;
            }
		}
    }
}