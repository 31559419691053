// signin locked
.signin-status {	
	padding-top:38px;
	padding-bottom:49px;
	@include screen(custom, max, $max-sm) {
		padding-top:21px;
		padding-bottom:29px;
	}
	.sign-in-title {
		font-size:32px;
		line-height:40px;
		@include font-family($font-semibold);
		text-align:center;
		margin-bottom: 6px;
		@include screen(custom, max, $max-sm) {
			font-size:24px;
			line-height:32px;
			margin-bottom:6px;
		}
	}
	.sign-in-text {
		font-size:16px;
		line-height:24px;
		color:$color-dimgray;
		@include font-family($font-regular);
		text-align:center;
		@include screen(custom, min, $min-md){
			max-width:74%;
			min-width:635px;
			margin:0 auto 28px;
		}
		// @include screen(custom, min, $min-sm){
		// 	max-width:87%;
		// }
		@include screen(custom, max, $max-sm) {
			font-size:14px;
			line-height:20px;
		}
	}
	dl{
		dt{
			font-size:16px;
			line-height:24px;
			color:$color-nightrider;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-sm) {
				font-size:14px;
				line-height:20px;
			}
		}
		dd{
			margin-top:6px;
			input{
				width:100%;
			}
			@include screen(custom, max, $max-sm) {
				margin-top:5px;
			}
		}
	}
	.dl_box{
		.dt_box{
			font-size:16px;
			line-height:24px;
			color:$color-nightrider;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-sm) {
				font-size:14px;
				line-height:20px;
			}
		}
		.dd_box{
			margin-top:6px;
			input{
				width:100%;
			}
			@include screen(custom, max, $max-sm) {
				margin-top:5px;
			}
		}
	}
	.btn-area{
		text-align:center;
		margin-top:40px;
		button{width:244px;}
		@include screen(custom, max, $max-sm) {
			margin-top:24px;
			button{width:100%;}
		}
	}
}

//LGEUSEMP-1 modify
// signin guidance
.signin-guidance {
	padding-top:112px;
	padding-bottom:101px;
	@include screen(custom, max, $max-sm) {
		padding-top:60x;
		padding-bottom:60px;
	}
	.title, .title h2 {
		font-size:32px;
		@include font-family($font-semibold);
		text-align:center;
		color:$color-black;
		@include screen(custom, max, $max-sm) {
			font-size:24px;
			line-height:32px;
			margin-bottom:6px;
		}
	}
	.title.title-sm, .title.title-sm h2 {
		margin-bottom:4px;
		font-size:20px;
		@include screen(custom, max, $max-sm) {
			margin-bottom:0;
			font-size:16px;
			line-height:24px;
		}
	}
	.text {
		margin-top:1px;
		margin-bottom: 0;
		font-size:16px;
		line-height:24px;
		color:$color-nightrider;
		@include font-family($font-regular);
		text-align:center;
		@include screen(custom, max, $max-sm) {
			font-size:14px;
			line-height:20px;
		}
		.link-text {
			font-size: 16px;
			@include screen(custom, max, $max-sm) {
				font-size:14px;
			}
		}
		& + .text {
			margin-top:16px;
			@include screen(custom, max, $max-sm) {margin-top:9px;}
		}
	}
	.email{
		@include font-family($font-semibold);
		color:$color-carmine;
	}
	.btn-area{
		text-align:center;
		margin-top:40px;
		.btn {
			width:248px;
			margin:0 0 0 6px;
			&:first-child {margin-left:0;}
		}
		@include screen(custom, max, $max-sm) {
			margin-top:24px;
			.btn {
				width:100%;
				margin:10px 0 0 0;
				&:first-child {margin-top:0;}
			}
		}
	}
	.guidance-desc {
		margin-bottom:25px;
		padding-bottom:24px;
		text-align:center;
		border-bottom:1px solid $line-gray;
		@include screen(custom, max, $max-sm) {
			margin-bottom:24px;
			padding-bottom:25px;
			font-size: 14px;
			line-height: 20px;
		}
		&.type-last {
			margin:26px 0 0;
			padding:24px 0 0;
			border-top:1px solid $line-gray;
			border-bottom:none;
			@include screen(custom, max, $max-sm) {
				margin-top:25px;
				padding-top:25px;
			}
		}
	}
	&.type-thin {
		padding-top:42px;
		padding-bottom:50px;
		@include screen(custom, max, $max-sm) {
			padding-top:22px;
			padding-bottom:30px;
		}
	}
}

.modal {
	.text-content {
		padding-top:26px;
		padding-bottom:43px;
		border-bottom:1px solid $line-gray;
		&.type-center {text-align:center;}
		.email {color:$color-carmine;}
		@include screen(custom, max, $max-sm) {
			padding-top:16px;
			padding-bottom:33px;
		}
	}
}
//LGEUSEMP-1 modify