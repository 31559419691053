.not-yet-member-area {
	.area-title {
		// text-align: center;
		@include font-family($font-semibold);
		font-size:24px;
		line-height:40px;
		color:$color-black;
		@include screen(custom, max, $max-sm){
			font-size:20px;
			line-height:28px;
			text-align: center;
		}
	}
	.contents-area {
		margin-top: 22px;
		@include screen(custom, max, $max-sm){
			margin-top: 17px;
		}
		.content-box {
			margin-top: 16px;
			@include screen(custom, max, $max-sm){
				margin-top: 9px;
			}
		}
		.box-title {
			@include font-family($font-semibold);
			font-size:16px;
			line-height:24px;
			color:$color-black;
		}
		.box-content {
			margin-top: 8px;
			color:$color-dimgray;
			@include screen(custom, max, $max-sm){
				font-size:14px;
				line-height:20px;
				margin-top: 3px;
			}
			// reset
			p {margin:0;}
		}		
	}
	/* PJTWAUS-1 : 20200312 add */
	dl.contents-area{
		margin-bottom: 0;
		dt{
			margin-top: 16px;
		}
	}
	/*// PJTWAUS-1 : 20200312 add */
	.sub-area {
		margin-top: 36px;
		@include screen(custom, max, $max-sm){
			margin-top: 17px;
		}
		.market-copy {
			line-height: 24px;
			margin-top: 24px;
			color:$color-nightrider;
			@include screen(custom, max, $max-sm){
				font-size:14px;
			}
		}
		.linker {
			margin-left: 5px;
			color:$color-carmine;
			text-decoration:underline;
			@include screen(custom, max, $max-sm){
				font-size:14px;
			}
		}
	}
}
.guest-order-status {
	.area-title {
		// text-align: center;
		@include font-family($font-semibold);
		font-size:24px;
		line-height:40px;
		color:$color-black;
		@include screen(custom, max, $max-sm){
			font-size:20px;
			line-height:28px;
			text-align: center;
		}
	}
	.desc {
		font-size:16px;
		line-height:24px;
		color:$color-dimgray;
		margin-right: 5px;
		@include screen(custom, max, $max-sm) {
			text-align: center;
			font-size:14px;
			line-height:20px;
			margin: 5px 0 0 0;
			color:$color-black;

			a.link-text {
				margin-left: 5px;
			}
		}
	}
}